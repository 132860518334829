<template>
    <div>
        <el-page-header @back="back" :content="title || options.title"></el-page-header>
        <el-form class="detail_form" size="small" label-position="right" label-width="130px">
            <div class="form_item">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="企业名称:">
                            <span>{{ options.unitNa || options.cName || '-' }}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="16">
                        <el-form-item label="地址:">
                            <span>{{ options.addr || '' }}</span>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="消费总金额:">
                            <span>{{ consumption }}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="充值总金额:">
                            <span>{{ charge }}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="余额:">
                            <span>{{ balance }}</span>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
        </el-form>
        <el-form class="detail_form" size="small" label-position="right" label-width="130px">
            <div class="form_item">
                <div class="head_title">历史消费记录</div>
                <jy-query class="query_area" size="small">
                    <jy-query-item label="交易时间">
                        <el-date-picker type="daterange" :default-time="['00:00:00', '23:59:59']" :clearable="false" value-format="yyyy-MM-dd HH:mm:ss" :value="[consumptionForm.createTBegin, consumptionForm.createTEnd]" @input="onConsumptionDateInput"></el-date-picker>
                    </jy-query-item>
                    <template slot="rightCol">
                        <el-button size="small" type="primary" @click="queryConsumptionList">查询</el-button>
                        <el-button size="small" type="primary" @click="consumptionFormReset">重置</el-button>
                        <!-- <el-button size="small" type="primary">导出</el-button> -->
                    </template>
                </jy-query>
                <div class="jy-table" v-loading="consumptionLoading">
                    <jy-table :data="consumptionList">
                        <jy-table-column type="index" label="序号" width="60"></jy-table-column>
                        <jy-table-column label="交易时间" prop="tradeTime"></jy-table-column>
                        <jy-table-column label="工单编号" prop="wOrderId"></jy-table-column>
                        <jy-table-column label="消费流水号" prop="seriesNu"></jy-table-column>
                        <jy-table-column label="支付方式">
                            <template v-slot="scope">
                                {{ getCHLName(scope.row.tradeWay) }}
                            </template>
                        </jy-table-column>
                        <jy-table-column label="消费金额(元)" prop="amount"></jy-table-column>
                        <jy-table-column label="账户余额(元)" prop="aBalance"></jy-table-column>
                        <jy-table-column label="地磅名称" prop="wbNa"></jy-table-column>
                        <jy-table-column label="分拣中心" prop="tUnitNa"></jy-table-column>
                        <jy-table-column label="清运时间" prop="grossWT">
                        </jy-table-column>
                        <jy-table-column label="清运总重量">
                            <template v-slot="scope">
                                {{ getTotalNetWeight(scope.row.rWeight, scope.row.grossW, scope.row.tareW) }}{{ getWasteUnit(scope.row.wasteTId) }}
                            </template>
                        </jy-table-column>
                        <jy-table-column label="固废类别">
                            <template v-slot="scope">
                                {{ getWasteTNa(scope.row.wasteTId) }}
                            </template>
                        </jy-table-column>
                    </jy-table>
                </div>
                <jy-pagination @size-change="handleConsumptionSizeChange" @current-change="handleConsumptionCurrentChange"
                    :current-page="consumptionPageIndex" :page-size="consumptionPageSize" :total="consumptionTotal"></jy-pagination>
            </div>
        </el-form>
        <el-form class="detail_form" size="small" label-position="right" label-width="130px">
            <div class="form_item">
                <div class="head_title">历史充值记录</div>
                <jy-query class="query_area" size="small">
                    <jy-query-item label="交易时间">
                        <el-date-picker type="daterange" :default-time="['00:00:00', '23:59:59']" :clearable="false" value-format="yyyy-MM-dd HH:mm:ss" :value="[chargeForm.createTBegin, chargeForm.createTEnd]" @input="onChargeDateInput"></el-date-picker>
                    </jy-query-item>
                    <jy-query-item label="充值方式">
                        <el-select v-model="chargeForm.chl">
                            <el-option :value="0" label="企业对公转账"></el-option>
                            <el-option :value="1" label="现金"></el-option>
                        </el-select>
                    </jy-query-item>
                    <template slot="rightCol">
                        <el-button size="small" type="primary" @click="queryChargeList">查询</el-button>
                        <el-button size="small" type="primary" @click="chargeFormReset">重置</el-button>
                        <!-- <el-button size="small" type="primary">导出</el-button> -->
                    </template>
                </jy-query>
                <div class="jy-table" v-loading="chargeLoading">
                    <jy-table :data="chargeList">
                        <jy-table-column type="index" label="序号" width="60"></jy-table-column>
                        <jy-table-column label="交易时间" prop="tradeTime"></jy-table-column>
                        <jy-table-column label="充值单号" prop="tradeId"></jy-table-column>
                        <jy-table-column label="充值流水号" prop="seriesNu"></jy-table-column>
                        <jy-table-column label="充值方式">
                            <template v-slot="scope">
                                {{ getCHLName(scope.row.tradeWay) }}
                            </template>
                        </jy-table-column>
                        <jy-table-column label="充值金额(元)" prop="amount"></jy-table-column>
                        <jy-table-column label="账户余额(元)" prop="pBalance"></jy-table-column>
                        <jy-table-column label="备注" prop="remark"></jy-table-column>
                    </jy-table>
                </div>
                <jy-pagination @size-change="handleChargeSizeChange" @current-change="handleChargeCurrentChange"
                    :current-page="chargePageIndex" :page-size="chargePageSize" :total="chargeTotal"></jy-pagination>
            </div>
        </el-form>
    </div>
</template>

<script>
import {tradeWay} from '@/common/js/wordbook'
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
    },

    mounted() {
        this.getWasteTypeList();
        const { options } = this.$parent;
        if (options) {
            this.options = options;
            this.getAccount();
            this.getChargeList();
            this.getConsumptionList();
        }
    },

    data() {
        return {
            options: {},

            consumption: 0,
            charge: 0,
            balance: 0,

            wasteTypeList: [],
            chargeList: [],
            consumptionList: [],

            chargeForm: {
                createTBegin: '',
                createTEnd: '',
                chl: undefined
            },
            chargeLoading: false,
            chargePageSize: 10,
            chargePageIndex: 1,
            chargeTotal: 0,

            consumptionForm: {
                createTBegin: '',
                createTEnd: ''
            },
            consumptionLoading: false,
            consumptionPageSize: 10,
            consumptionPageIndex: 1,
            consumptionTotal: 0,
        }
    },

    methods: {
        back() {
            this.$emit('switch', { target: 'List' });
        },

        queryChargeList() {
            this.getChargeList()
        },

        queryConsumptionList() {
            this.getConsumptionList()
        },

        chargeFormReset() {
            this.chargePageIndex = 1
            this.chargePageSize = 10
            Object.keys(this.chargeForm).forEach(key => {
                this.chargeForm[key] = ''
            })
            this.chargeForm.chl = undefined
            this.queryChargeList()
        },

        consumptionFormReset() {
            this.consumptionPageIndex = 1
            this.consumptionPageSize = 10
            Object.keys(this.consumptionForm).forEach(key => {
                this.consumptionForm[key] = ''
            })
            this.queryConsumptionList()
        },

        getAccount(){
            let url = '/unitInfo/account/detail'
            this.$http.post(url,this.options.unitId).then(res=>{
                const {detail} = res
                if (detail) {
                    const {accountInfo} = detail
                    this.balance = accountInfo?accountInfo.balance:'-'
                }
            })
        },

        getChargeList() {
            this.chargeLoading = true;
            this.$http.post('/finance/account/detailList', {
                unitId: this.options.unitId,
                io: 0,
                pageSize: this.chargePageSize,
                pageIndex: this.chargePageIndex,
                ...this.chargeForm
            })
                .then(res => {
                    this.chargeTotal = res.detail.total;
                    this.charge = res.detail.extraData && res.detail.extraData.sum || 0;
                    this.chargeList = res.detail.data.reduce((prev, cur) => {
                        const obj = Object.assign({}, ...Object.values(cur));
                        obj.unitNa = cur.unitInfo  && cur.unitInfo.cName;
                        obj.tUnitNa = cur.tUnitInfo && cur.tUnitInfo.cName;
                        obj.wbNa = cur.weighBridge && cur.weighBridge.cName;
                        prev.push(obj);
                        return prev;
                    }, []);
                })
                .finally(() => {
                    this.chargeLoading = false;
                })
        },

        getConsumptionList() {
            this.consumptionLoading = true;
            this.$http.post('/finance/account/detailList', {
                unitId: this.options.unitId,
                io: 1,
                ...this.consumptionForm,
                pageIndex: this.consumptionPageIndex,
                pageSize: this.consumptionPageSize
            })
                .then(res => {
                    this.consumptionTotal = res.detail.total;
                    this.consumption =  res.detail.extraData && res.detail.extraData.sum || 0;
                    this.consumptionList = res.detail.data.reduce((prev, cur) => {
                        const obj = Object.assign({}, ...Object.values(cur));
                        obj.unitNa = cur.unitInfo  && cur.unitInfo.cName;
                        obj.tUnitNa = cur.tUnitInfo && cur.tUnitInfo.cName;
                        obj.wbNa = cur.weighBridge && cur.weighBridge.cName;
                        prev.push(obj);
                        return prev;
                    }, []);
                })
                .finally(() => {
                    this.consumptionLoading = false;
                })
        },

        getCHLName(chl) {
            let list = tradeWay()
            let item = list.find(one=>one.value==chl)
            return item ? item.label : '-';
        },

        getWasteTypeList() {
            this.$http
                .post("/wasteType/listAll")
                .then(res => {
                    this.wasteTypeList = res.detail;
                })
        },

        getWasteTNa(wasteTId) {
            return this.wasteTypeList.find(each => each.wasteTId === wasteTId).cName;
        },

        getWasteUnit(wasteTId) {
            return this.wasteTypeList.find(each => each.wasteTId === wasteTId).unit;
        },

        getTotalNetWeight(rWeight, grossW, tareW) {
            return (rWeight || 0) + (grossW || 0) - (tareW || 0);
        },

        onChargeDateInput(value) {
            [this.chargeForm.createTBegin, this.chargeForm.createTEnd] = value;
        },

        onConsumptionDateInput(value) {
            [this.consumptionForm.createTBegin, this.consumptionForm.createTEnd] = value;
        },

        handleChargeSizeChange(pageSize) {
            this.chargePageSize = pageSize;
            this.queryChargeList()
        },

        handleChargeCurrentChange(pageIndex) {
            this.chargePageIndex = pageIndex;
            this.queryChargeList()
        },

        handleConsumptionSizeChange(pageSize) {
            this.consumptionPageSize = pageSize;
            this.queryConsumptionList()
        },

        handleConsumptionCurrentChange(pageIndex) {
            this.consumptionPageIndex = pageIndex;
            this.queryConsumptionList()
        }
    }
}
</script>

<style lang="scss" scoped>
.avatar {
    width: 100px;
    height: 100px;
}

.detail_form {
    margin-top: 20px;

    .form_item {
        margin-bottom: 30px;

        .head_title {
            font-size: 18px;
        }

        .head_title::before {
            content: "";
            display: inline-block;
            width: 7px;
            height: 26px;
            margin-right: 5px;
            vertical-align: middle;
            background-color: #3498db;
        }
    }
}

.form_handle {
    text-align: center;
}
</style>